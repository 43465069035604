import { Layout, useAuthContext, useSignupContext } from "Components";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { Button } from "melodies-source/Button";
import { Body1, H1, H2 } from "melodies-source/Text";
import { styled } from "styled-components";
import { ReactComponent as SendIcon } from "assets/svg/send-circle-green.svg";
import { useEffect, useState } from "react";

export const RequestSent = () => {
  const isDesktop = useDesktopMediaQuery();
  const { data } = useSignupContext();
  const { user } = useAuthContext();
  const [userToken, setUserToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    user
      .getIdToken()
      .then(setUserToken)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [user]);

  const handleDone = () => {
    // remove subdomain dot per https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#write_a_new_cookie
    //  If a domain is specified, subdomains are always included.
    // Contrary to earlier specifications, leading dots in domain names
    // are ignored, but browsers may decline to set the cookie containing such dots.
    const cookieDomain = (process.env.REACT_APP_SL_URL || "").replace(
      "https://",
      "",
    );
    document.cookie = "token=" + encodeURIComponent(userToken);
    document.cookie =
      "token=" +
      encodeURIComponent(userToken) +
      `; domain=${cookieDomain}; path=/; Secure`;
    const dashboardUrl = new URL(process.env.REACT_APP_ARTIST_PORTAL_URL || "");
    dashboardUrl.pathname = "/go";
    dashboardUrl.searchParams.set("action", "go");
    dashboardUrl.searchParams.set(
      "url",
      process.env.REACT_APP_ARTIST_PORTAL_URL || "",
    );
    window.location.href = dashboardUrl.toString();
  };

  const Heading = isDesktop ? H1 : H2;
  return (
    <Layout>
      <Container>
        <ArtistImageContainer>
          <ArtistImage url={data.artistImage} />
          <StyledSendIcon />
        </ArtistImageContainer>
        <Heading>Request Sent</Heading>
        <Body1>
          Your request to join {data.artistName} was successfully submitted. An
          admin will review your request shortly.
        </Body1>
        <StyledButton loading={isLoading} onClick={handleDone}>
          Go to your dashboard
        </StyledButton>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Body1} {
    text-align: center;
  }
`;

interface ArtistImageProps {
  url: string;
}

const ArtistImage = styled.img<ArtistImageProps>`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  border: 2px solid #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
`;

const ArtistImageContainer = styled.div`
  position: relative;
`;

const StyledSendIcon = styled(SendIcon)`
  position: absolute;
  top: -6px;
  right: -6px;
`;
const StyledButton = styled(Button)`
  margin: 30px 0 10px 0;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
